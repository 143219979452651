<template>
  <q-form ref="editForm">
    <c-card title="기본정보" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn
            v-if="editable && saveData.length > 0"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="saveData"
            mappingType="PUT"
            label=""
            icon="save"
            @beforeAction="savePlan"
            @btnCallback="saveCallback" 
          />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :disabled="true"
            :comboItems="courseItems"
            type="edit"
            itemText="educationCourseName"
            itemValue="eduCourseId"
            name="eduCourseId"
            label="교육과정"
            v-model="educationInfo.eduCourseId"
          >
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-text
            required
            :editable="editable"
            label="교육명"
            name="educationName"
            v-model="multiData.educationName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            :required="true" 
            type="edit"
            codeGroupCd="EDU_CLASS_CD"
            itemText="codeName"
            itemValue="code"
            name="educationTypeCd"
            label="교육구분"
            v-model="multiData.educationTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            label="교육장소"
            name="educationLocation"
            v-model="multiData.educationLocation">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            type="edit"
            codeGroupCd="EDU_METHOD_CD"
            itemText="codeName"
            itemValue="code"
            name="educationMethodCd"
            label="교육방법"
            v-model="multiData.educationMethodCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            suffix="원"
            type="number"
            :editable="editable"
            label="예상 계획"
            name="estimatedEducationExpenses"
            v-model="multiData.estimatedEducationExpenses">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            :editable="editable"
            :required="true" 
            :data="multiData"
            label="주관 부서"
            name="departmentDeptCd"
            v-model="multiData.departmentDeptCd">
          </c-dept>
        </div>
      </template>
    </c-card>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-table
          ref="table"
          title="교육계획"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="saveData"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :hideBottom="true"
          :isExcelDown="false"
          noDataLabel="교육내용이 없습니다."
          :editable="editable"
          rowKey="eduEducationId"
          selection="multiple"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="추가" :showLoading="false"  icon="add" @btnClicked="addPlan" />
              <c-btn v-if="editable && saveData.length > 0" label="제외" :showLoading="false" icon="remove" @btnClicked="removePlan" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="props.row.eduAttendeeList && props.row.eduAttendeeList.length > 0 && col.name === 'attendeeUserNames'">
              <q-chip
                v-for="(user, index) in props.row.eduAttendeeList"
                :key="index"
                outline square 
                :removable="editable"
                color="primary"
                text-color="white"
                class="q-ma-none customchipdept"
                @remove="removeUser(props.row, user)">
                {{user.attendeesName}}
              </q-chip>
            </template>
            <template v-if="col.name==='educationDate'">
              <c-datepicker
                dense
                class="tableDatepicker"
                :disable="!editable"
                :range="props.row.educationRangeFlag === 'Y' ? true : false"
                v-model="props.row.educationDate"
              ></c-datepicker>
            </template>
            <template v-if="col.name === 'click1'">
              <q-chip
                v-if="props.row.editFlag !== 'C'"
                color="blue"
                outline square
                :clickable="true"
                :editable="editable"
                text-color="white"
                @click="linkClick(props.row, col.name)">
                {{'클릭'}}
              </q-chip>
              <q-chip
                v-else
                color="red"
                outline square
                text-color="white">
                <q-avatar icon="info" color="red" text-color="white" />
                {{'미저장'}}
              </q-chip>
            </template>
            <template v-if="col.name === 'click2'">
              <q-chip
                color="blue"
                outline square
                :clickable="true"
                :editable="editable"
                text-color="white"
                @click="selectTargetUser(props.row, col.name)">
                {{'클릭'}}
              </q-chip>
            </template>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-table
          ref="gridTargetUser"
          title="교육대상자 목록"
          :columns="gridTargetUser.columns"
          :gridHeight="gridTargetUser.height"
          :data="tempEduAttendeeList"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :merge="gridTargetUser.merge"
          :isExcelDown="false"
          :hideBottom="true"
          noDataLabel="교육대상자가 없습니다."
          :editable="editable && isAdd"
          rowKey="attendeesNo"
          selection="multiple"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable && tempEduAttendeeList.length > 0 && isAdd" label="제외" :showLoading="false" icon="remove" @btnClicked="removeTargetUser" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-card title="교육대상자 추가" class="cardClassDetailForm">
          <template slot="card-detail">
            <div class="col-6">
              <c-dept
                :editable="editable && isAdd"
                :isFirstValue="false"
                :plantCd="searchParam.plantCd"
                label="부서"
                name="deptCd"
                v-model="searchParam.deptCd">
              </c-dept>
            </div>
            <div class="col-6">
              <c-text
                :editable="editable && isAdd"
                label="사용자"
                name="userName"
                v-model="searchParam.userName"
                @input="getTargetUserList">
              </c-text>
            </div>
            <div class="col-12" style="margin-top: -20px">
              <div class="row">
                <div class="col-1" style="line-height: 35px; text-align:center;padding-top:100px !important">
                  <c-btn v-if="editable && isAdd" label="" icon="arrow_back_ios" color="blue-7" @btnClicked="selectAdd" />
                </div>
                <div class="col-11">
                  <c-table
                    ref="gridUser"
                    title="사용자 목록"
                    tableId="gridUser"
                    :columns="gridUser.columns"
                    :gridHeight="gridUser.height"
                    :data="gridUser.data"
                    :columnSetting="false"
                    :isFullScreen="false"
                    :usePaging="false"
                    :editable="editable && isAdd"
                    :expandAll="true"
                    :filtering="false"
                    :isExcelDown="false"
                    selection="multiple"
                    rowKey="userId"
                  >
                    <!-- 버튼 영역 -->
                    <template slot="table-button">
                      <q-btn-group outline >
                        <c-btn
                          label="검색"
                          icon="search" @btnClicked="getTargetUserList" v-if="editable && isAdd"/>
                      </q-btn-group>
                    </template>
                  </c-table>
                </div>
              </div>
            </div>
          </template>
        </c-card>
      </div>
    </div>
  <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>
<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'education-mutli-plan',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        eduCourseId: '',
      }),
    },
    count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      editable: true,
      isSave: false,
      saveUrl: 'selectConfig.sop.edu.course.multisave.url',
      deleteUrl: '',
      targetUserUrl: '',
      educationInfo: {
        plantName: '',
        educationCourseName: '',
        educationKindCdLargeName: '',
        educationKindCdSmallName: '',
        educationTypeName: '',
        legalEducationFlagName: '',
        educationPurpose: '',
        educationTimeName: '',
        relatedLawsName: '',
      },
      saveData: [],
      grid: {
        columns: [
        ],
        height: '700px',
        data: [],
      },
      gridTargetUser: {
        columns: [
          {
            name: 'attendeesDeptName',
            field: 'attendeesDeptName',
            style: 'width:25%',
            label: '부서',
            align: 'center',
            sortable: false,
          },
          {
            name: 'attendeesPositionName',
            field: 'attendeesPositionName',
            style: 'width:25%',
            label: '직책',
            align: 'center',
            sortable: false,
          },
          {
            name: 'seniorityName',
            field: 'seniorityName',
            style: 'width:25%',
            label: '법규/선해임',
            align: 'center',
            sortable: false,
          },
          {
            name: 'attendeesName',
            field: 'attendeesName',
            label: '대상자명',
            style: 'width:25%',
            align: 'center',
            sortable: false
          },
        ],
        height: '700px',
        data: [],
      },
      gridUser: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '사용자',
            align: 'center',
            sortable: false,
          },
        ],
        height: '570px',
        data: [],
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      period: [],
      searchParam: {
        plantCd: null,
        userName: '',
        deptCd: '',
      },
      courseItems: [],
      multiData: {
        educationName: '',
        educationTypeCd: null,
        educationMethodCd: null,
        educationLocation: '',
        sopMocId: '',
        assessmentName: '', // 위험성평가
        selfInspectionTitle: '', // 자체감사
        departmentDeptCd: '',
      },
      tempEduAttendeeList: [],
      isAdd: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
    'count'() {
      this.getList();
    }
  },
  methods: {
    init() {
      if (this.popupParam.eduCourseId) {
        this.educationInfo.eduCourseId = this.popupParam.eduCourseId;
      }
      this.editable = this.$route.meta.editable;
      this.targetUserUrl = selectConfig.sop.edu.user.list.url;
      this.getUrl = selectConfig.sop.edu.course.get.url;
      this.listUrl = selectConfig.sop.edu.course.multilist.url;
      this.saveUrl = transactionConfig.sop.edu.course.multisave.url;
      this.deleteUrl = transactionConfig.sop.edu.course.multidelete.url;
      this.courseUrl = selectConfig.sop.edu.course.list.url;
      this.setHeader();
      this.getList();
      // this.getPlanList();
    },
    getCourse() {
      if (this.educationInfo.plantCd) {
        this.$http.url = this.courseUrl;
        this.$http.type = 'GET';
        this.$http.param = {plantCd: this.educationInfo.plantCd, useFlag: 'Y'};
        this.$http.request((_result) => {
          this.courseItems = _result.data;
        },);
      } else {
        this.courseItems = [];
      }
    },
    getTargetUserList() {
      this.$set(this.searchParam, 'plantCd', this.educationInfo.plantCd)
      this.$http.url = this.targetUserUrl;
      this.$http.type = 'GET';
      this.$http.isLoading = false;
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.gridUser.data = _result.data;
        this.$refs['gridUser'].selected = [];
      },);
    },
    /* eslint-disable no-unused-vars */ 
    setHeader() {
      this.$comm.getComboItems('EDU_CLASS_CD').then(_result => {
        this.$comm.getComboItems('EDU_METHOD_CD').then(_result2 => {
          this.grid.columns = [
            // {
            //   name: 'click1',
            //   field: 'click1',
            //   label: '상세보기',
            //   align: 'center',
            //   type: 'custom',
            //   style: 'width: 70px',
            //   sortable: false,
            // },
            // {
            //   name: 'educationName',
            //   field: 'educationName',
            //   required: true,
            //   label: '교육명',
            //   align: 'left',
            //   type: 'text',
            //   style: 'width:200px', 
            //   sortable: false,
            // },
            {
              name: 'educationRangeFlag',
              field: 'educationRangeFlag',
              label: '기간,일',
              align: 'center',
              style: 'width:90px',
              sortable: true,
              type: 'check',
              true: 'Y',
              false: 'N',
            },
            {
              required: true,
              name: 'educationDate',
              field: 'educationDate',
              label: '교육기간',
              minuteStep: 10,
              type: 'custom',
              style: 'width:200px',
              align: 'center',
              sortable: false,
            },
            {
              name: 'educationTime',
              field: 'educationTime',
              required: true,
              label: '교육시간',
              setHeader: true,
              range: true,
              minuteStep: 10,
              type: 'date',
              start: "08:00",
              dateType: 'time',
              style: 'width:150px',
              align: 'center',
              sortable: false,
            },
            // {
            //   name: 'educationTypeCd',
            //   field: 'educationTypeCd',
            //   setHeader: true,
            //   required: true,
            //   label: '교육구분',
            //   align: 'center',
            //   style: 'width:100px',
            //   type: 'select',
            //   comboItems: _result,
            //   sortable: false,
            // },
            // {
            //   name: 'mainTargetAudience',
            //   field: 'mainTargetAudience',
            //   label: '주요 대상자',
            //   type: 'text',
            //   style: 'width:150px',
            //   align: 'left',
            //   sortable: false,
            // },
            // {
            //   name: 'educationMethodCd',
            //   field: 'educationMethodCd',
            //   setHeader: true,
            //   required: true,
            //   label: '교육방법',
            //   align: 'center',
            //   style: 'width:100px',
            //   type: 'select',
            //   comboItems: _result2,
            //   sortable: false,
            // },
            // {
            //   name: 'educationLocation',
            //   field: 'educationLocation',
            //   label: '교육장소',
            //   type: 'text',
            //   style: 'width:100px',
            //   align: 'left',
            //   sortable: false,
            // },
            // {
            //   name: 'documentStatusName',
            //   field: 'documentStatusName',
            //   label: '진행상태',
            //   style: 'width:100px',
            //   align: 'center',
            //   sortable: false,
            // },
            // {
            //   name: 'attendeeUserNames',
            //   field: 'attendeeUserNames',
            //   label: '대상자',
            //   align: 'left',
            //   type: 'custom',
            //   sortable: false,
            // },
            {
              name: 'click2',
              field: 'click2',
              label: '대상자추가',
              align: 'center',
              type: 'custom',
              style: 'width: 80px',
              sortable: false,
            },
          ]
        });
      });
    },
    getList() {
      if (this.popupParam.eduCourseId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.eduCourseId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.educationInfo = this.$_.clone(_result.data);
          this.getCourse();
        },);
      }
    },
    getPlanList() {
      if (this.popupParam.eduCourseId) {
        this.$http.url = this.$format(this.listUrl, this.popupParam.eduCourseId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.saveData = this.$_.clone(_result.data);
          this.$_.forEach(this.saveData, _item => {
            _item.educationDate = [_item.educationStartDate, _item.educationEndDate]
            _item.educationTime = [_item.educationStartTime, _item.educationEndTime]
          })
        },);
      }
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.title = "교육계획 및 결과 상세"; // 교육계획 상세
      this.popupOptions.param = {
        eduEducationId: row.eduEducationId ? row.eduEducationId : '',
        stepCd: row.documentStatusCd === 'ESC000001' ?  'ESC0000005' : 'ESC0000010',
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/sop/edu/result/educationResultDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    addPlan() {
      this.saveData.splice(0, 0, {
        // 교육과정 culumn
        eduCourseId: this.educationInfo.eduCourseId,
        plantCd: this.educationInfo.plantCd,
        educationCourseName: this.educationInfo.educationCourseName,
        educationCycle: this.educationInfo.educationCycle,
        educationKindCdLarge: this.educationInfo.educationKindCdLarge,
        educationKindCdSmall: this.educationInfo.educationKindCdSmall,
        legalEducationFlag: this.educationInfo.legalEducationFlag,
        educationPurpose: this.educationInfo.educationPurpose, 
        mainTargetAudience: this.educationInfo.mainTargetAudience,
        estimatedEducationExpenses: this.educationInfo.estimatedEducationExpenses,
        relationLaws: this.educationInfo.relationLaws,
        educationTime: ['08:00', '17:00'],
        educationRangeFlag: 'Y',
        // 교육계획 culumn
        eduEducationId: uid(),
        educationName: '',
        educationTypeCd: null,
        educationMethodCd: null,
        educationLocation: '',
        sopMocId: '',
        assessmentName: '', // 위험성평가
        selfInspectionTitle: '', // 자체감사
        departmentDeptCd: '',
        documentStatusName: '작성중',
        eduAttendeeList: [],
        editFlag: 'C',
      })
    },
    savePlan() {
      if (this.$comm.validTable(this.grid.columns, this.saveData)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까? (저장 후 수정은 교육과정별 교육계획 및 결과 탭에서 가능합니다.)',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.saveData, _item => {
              if (_item.educationRangeFlag === 'Y') {
                _item.educationStartDate = _item.educationDate[0];
                _item.educationEndDate = _item.educationDate[1];
              } else {
                _item.educationStartDate = _item.educationDate;
                _item.educationEndDate = _item.educationDate;
              }
              _item.educationStartTime = _item.educationTime[0];
              _item.educationEndTime = _item.educationTime[1];
              _item.regUserId = this.$store.getters.user.userId;
              _item.chgUserId = this.$store.getters.user.userId;
              _item.educationName = this.multiData.educationName;
              _item.educationTypeCd = this.multiData.educationTypeCd;
              _item.educationLocation = this.multiData.educationLocation;
              _item.educationMethodCd = this.multiData.educationMethodCd;
              _item.estimatedEducationExpenses = this.multiData.estimatedEducationExpenses;
              _item.departmentDeptCd = this.multiData.departmentDeptCd;
            })

            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.tempEduAttendeeList = [];
      this.isAdd = false;
      this.saveData = [];
      this.multiData.educationTypeCd = null;
      this.multiData.educationMethodCd = null;
      this.multiData.educationLocation = '';
      this.multiData.estimatedEducationExpenses = '';
      this.multiData.educationName = '';
      // this.getPlanList();
      // this.emit('SAVE');
    },
    removePlan() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.saveData = this.$_.reject(this.saveData, item);
        })
        this.$refs['table'].$refs['compo-table'].clearSelection();

        // window.getApp.$emit('CONFIRM', {
        //   title: '확인',
        //   message: '삭제하시겠습니까?',
        //   type: 'warning', // success / info / warning / error
        //   // 확인 callback 함수
        //   confirmCallback: () => {
        //     this.$http.url = this.deleteUrl;
        //     this.$http.type = 'DELETE';
        //     this.$http.param = {
        //       data: Object.values(selectData)
        //     };
        //     this.$http.request(() => {
        //       // this.getItemList({code: selectData[0].codeGrpCd});
        //       window.getApp.$emit('APP_REQUEST_SUCCESS');
              
        //       this.$_.forEach(selectData, item => {
        //         this.saveData = this.$_.reject(this.saveData, item);
        //       })
        //       this.$refs['table'].$refs['compo-table'].clearSelection();
        //       this.getPlanList();
        //     },);
        //   },
        //   // 취소 callback 함수
        //   cancelCallback: () => {
        //   },
        // });
      }
    },
    removeUser(row, user) {
      // if (!row.deleteEduAttendeeList) {
      //   row.deleteEduAttendeeList = []
      // }
      // if (this.$_.findIndex(row.deleteEduAttendeeList, { attendeesId: user.userId }) === -1
      //   && row.editFlag !== 'C') {
      //     row.deleteEduAttendeeList.push(user)
      // }
      row.eduAttendeeList = this.$_.reject(row.eduAttendeeList, user)

      // if (!row.deleteEduAttendeeList) {
      //   row.deleteEduAttendeeList = []
      // }
      // if (this.$_.findIndex(row.deleteEduAttendeeList, { attendeesId: user.userId }) === -1
      //   && row.editFlag !== 'C') {
      //     row.deleteEduAttendeeList.push(user)
      // }
      // row.eduAttendeeList = this.$_.reject(row.eduAttendeeList, user)
    },
    selectTargetUser(row, col, index) {
      if (!row.eduAttendeeList) row.eduAttendeeList = [];
      this.isAdd = true;
      this.tempEduAttendeeList = row.eduAttendeeList;
    },
    removeTargetUser() {
      let selectData = this.$refs["gridTargetUser"].selected;
      this.$_.forEach(selectData, _item => {
        this.tempEduAttendeeList.splice(this.$_.findIndex(this.tempEduAttendeeList, { lineId: _item.attendeesNo }), 1);
      })
      this.$refs["gridTargetUser"].selected = [];
    },
    selectAdd() {
      this.$refs['table'].selected = [];
      let selectData = this.$refs["gridUser"].selected;
      if (selectData && selectData.length > 0) {
        this.$_.forEach(selectData, _item => {
          let index = this.$_.findIndex(this.tempEduAttendeeList, {
            attendeesId: _item.userId,
          });
          // 부서 EATC00003
          if (index === -1) {
            this.tempEduAttendeeList.splice(0, 0, {
              eduCourseId: this.educationInfo.eduCourseId,
              attendeesNo: uid(),
              attendeesTypeCd: 'EATC00003',
              eduEducationId: '',
              attendeesTypeName: '',
              attendeesPositionName: _item.jobName,
              attendanceTime: '',
              attendeesDeptName: _item.deptName,
              seniorityName: _item.seniorName,
              attendeesId: _item.userId,
              enterDate: _item.enterDate,
              attendeesName: _item.userName,
              editFlag: 'C'
            })
          }
        })
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '사용자 목록에서 대상자를 선택하세요.',
          type: 'warning', // success / info / warning / error
        });
      }
    },
  }
};
</script>